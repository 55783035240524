
<template>
	<div>
		<v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
		<v-container v-else class="my-2">
			<!-- <v-layout row wrap px-0 mb-4>
                    <v-flex xs9>
                        <v-breadcrumbs class="px-0" :items="$store.getters.language.data.extra_days.edit_path">
                            <template v-slot:item="{ item }">
                                <v-breadcrumbs-item :to="item.path" :disabled="item.disabled">
                                    {{ item.title.toUpperCase() }}
                                </v-breadcrumbs-item>
                            </template>
                        </v-breadcrumbs>
                    </v-flex>
                    <v-flex xs3>
                    </v-flex>
                </v-layout> -->
			<form @submit.prevent="updateExtraDays" autocomplete="off">
				<v-layout row wrap>
					<v-flex xs12 lg4 xl4 md6 sm6>
						<v-select class="mx-1" clearable :items="employees" v-model="extra_days.employee_id" dense filled outlined item-text="employee_name" item-value="employee_id" :return-object="false" :label="$store.getters.language.data.employees.employee_name">
						</v-select>
					</v-flex>
					<v-flex xs12 lg4 xl4 md6 sm6>
						<v-text-field v-model="extra_days.extra_day_amount" type="number" :label="$store.getters.language.data.extra_days.extra_day_amount" class="mx-1" filled outlined dense required>
						</v-text-field>
					</v-flex>
					<v-flex xs12 lg4 xl4 md6 sm6>
						<v-text-field v-model="extra_days.extra_day_date" type="date" :label="$store.getters.language.data.extra_days.extra_day_date" class="mx-1" filled outlined dense required>
						</v-text-field>
					</v-flex>

					<v-flex xs12 lg6 xl6 md6 sm6>
						<v-textarea v-model="extra_days.extra_day_note" type="textarea" :label="$store.getters.language.data.extra_days.extra_day_note" class="mx-1" filled outlined dense>
						</v-textarea>
					</v-flex>
					<!-- <v-flex xs12 lg2 xl2 md3 sm4>
						<v-text-field v-model="extra_days.extra_day_insert_date" type="datetime-local" :label="$store.getters.language.data.extra_days.extra_day_insert_date" class="mx-1" filled outlined dense>
						</v-text-field>
					</v-flex> -->
					<v-flex xs12 lg3 xl3 md6 sm6>
						<v-select class="mx-1" clearable :items="users" v-model="extra_days.user_id" dense filled outlined item-text="user_name" item-value="user_id" :return-object="false" :label="$store.getters.language.data.users.user_name" disabled>
						</v-select>
					</v-flex>
					<v-flex xs12 lg2 xl2 md2 sm4>
						<v-btn color="success" type="submit">{{$store.getters.language.data.extra_days.update_btn}}</v-btn>
					</v-flex>
				</v-layout>
			</form>
		</v-container>
		<v-snackbar v-model="snackbar.value" :color="snackbar.color">
			{{snackbar.text}}
		</v-snackbar>
	</div>
</template>
<script>
	import requests from './../../requests/extra_days.request.js'
	export default {
		data() {
			return {
				extra_days: {},
				id: '',
				loading: true,
				snackbar: {
					value: false,
					text: '',
					color: ''
				},
				employees: [], users: [],
			}
		},
		computed: {

			page() {
				const pageKey = 'EXTRADAYS'
				return {}
			}
		},
		mounted() {
			this.id = this.$route.params.id
			this.getOneExtraDays()
		},
		methods: {
			getOneExtraDays() {
				this.loading = true
				requests.getOneExtraDays(this.id).then(r => {
					if (r.status == 200) {
						this.extra_days = r.data.extra_days

						this.employees = r.data.employees

						this.users = r.data.users
						this.extra_days.extra_day_date = new Date(this.extra_days.extra_day_date).toISOString().substr(0, 10)
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read ExtraDays',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read ExtraDays',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
			updateExtraDays() {
				this.loading = true
				delete this.extra_days.extra_day_insert_date
				this.extra_days.user_id = this.$store.getters.user.user_id
				requests.updateExtraDays(this.id, this.extra_days).then(r => {
					if (r.status == 200) {
						this.snackbar = {
							value: true,
							text: 'ExtraDays Updated',
							color: 'success'
						}
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Update Faild',
							color: 'error'
						}
						this.loading = false
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Update Faild',
							color: 'error'
						}
						this.loading = false
					})
					.finally(() => {
						this.loading = false
					})
			},
		},
	}
</script>
                    